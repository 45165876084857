import request from '@/utils/request'

export default {
  // 创建下载订单
  postCreateOrder(data,key,time,md5){
    return request({
      url: '/case/create/order',
      method: 'post',
      headers: {
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: data
    })
  },
  // 分页查询
  postPage(data){
    return request({
      url: '/case/page',
      method: 'post',
      data: data
    })
  },
  // 预览
  preview(id){
    return request({
      url: '/case/preview/'+ id,
      method: 'get'
    })
  },
  // 创建vip下载订单
  getVipDownLink(id,token,key,time,md5){
    return request({
      url: '/case/getDownLink',
      method: 'post',
      headers: {
        token: token,
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: {
        caseId: id
      }
    })
  }
}
