<template>
  <div class="case-main-box">
<!--    <FormTitle :infoName="'案例库'" />-->
    <!-- 搜索栏 -->
    <div class="search-box search-pc-show">
      <el-input class="input-with-select" placeholder="请输入内容" v-model="queryInfo.title" @input="closeActive" @change="changeSearch">
        <el-select filterable v-model="select" slot="prepend" placeholder="请选择" @change="changeType">
          <el-option :label="item.name" :value="index + 1" v-for="(item,index) in transferMent"></el-option>
        </el-select>
        <el-button slot="append" icon="el-icon-search" @click="search">搜索</el-button>
      </el-input>
    </div>
    <div class="search-box2 search-h5-show">
      <el-input size="small" class="input-with-select" placeholder="请输入内容" v-model="queryInfo.title" @input="closeActive" @change="changeSearch">
        <el-select class="search-h5-select" filterable v-model="select" slot="prepend" placeholder="请选择" @change="changeType">
          <el-option class="search-h5-option" :label="item.name" :value="index + 1" v-for="(item,index) in transferMent"></el-option>
        </el-select>
        <el-button class="search-h5-btn" slot="append" icon="el-icon-search" @click="search"></el-button>
      </el-input>
    </div>
    <!-- 热门搜索 -->
    <div class="hot-search-box search-pc-show">
      <div class="tit">热门搜索：</div>
      <ul>
        <li :class="{'active': item.active === 1}" v-for="(item,index) in searchData" @click="searchBtn(item,index)">{{item.name}}</li>
      </ul>
    </div>
    <div class="hot-search-box2 search-h5-show">
      <div class="tit" @click="searchDrawer = true">
        热门搜索
        <i class="el-icon-arrow-right"></i>
      </div>
      <el-drawer
        title="热门搜索"
        custom-class="search-h5-box"
        :visible.sync="searchDrawer"
        :direction="'rtl'">
        <ul class="search-h5-ul">
          <li :class="{'active': item.active === 1}" v-for="(item,index) in searchData" @click="searchBtn(item,index)">{{item.name}}</li>
        </ul>
      </el-drawer>
    </div>
    <!-- 内容列表 -->
    <div class="case-content" v-if="productList && productList.length > 0">
      <div class="list-box" @mouseenter="mouseEnter(index)" @mouseleave="mouseLeave(index)" v-for="(item,index) in productList">
        <div class="list-icon">
          <img src="@/assets/images/icon/icon-word.png" alt="" />
        </div>
        <div class="cont">
          <div class="cont-txt no-select" v-disable-select>{{item.description}}</div>
        </div>
        <div class="list-item">
          <!--          <div class="tag" v-if="item.tags.length > 0">-->
          <!--            <span v-for="val in item.tags">{{val}}</span>-->
          <!--          </div>-->
          <div class="list-title">
            {{item.title}}
          </div>
          <div class="show-btn2">
            <div class="e-btn" @click="previewBtn(item)">
              <i class="el-icon-zoom-in"></i>
              <span>预览</span>
            </div>
            <div class="e-btn btn-pc-show" @click="downloadBtn(item)">
              <i class="el-icon-download"></i>
              <span>下载</span>
            </div>
            <div class="e-btn btn-h5-show" @click="downloadBtn2(item)">
              <i class="el-icon-download"></i>
              <span>下载</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="noMore">没有更多了</div>
    </div>
    <div v-else>
      <el-empty description="没有搜索到结果"></el-empty>
    </div>

    <div class="search-pc-show">
      <el-pagination v-show="total > 0"
                     class="page-pagination"
                     :page-size="queryInfo.pageSize"
                     background
                     @current-change="handleCurrentChange"
                     layout="prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </div>
    <div class="search-h5-show">
      <el-pagination v-show="total > 0"
                     class="page-pagination"
                     :pager-count="5"
                     :page-size="queryInfo.pageSize"
                     small
                     background
                     @current-change="handleCurrentChange"
                     layout="prev, pager, next"
                     :total="total">
      </el-pagination>
    </div>

    <el-dialog :title="'《'+dialog.title+'》'"
               :visible.sync="centerDialogVisible"
               class="dialog-box"
               @close="closePreview"
               center>
      <div class="details-box no-select" v-disable-select>
        <div class="details-list" v-for="item in dialog.content">
          <div class="case-container" v-html="item.content"></div>
        </div>
      </div>
      <div class="dialog-btn dialog-pc-show">
        <el-button type="primary" @click="openDownload('pc')">下 载</el-button>
      </div>
      <div class="dialog-btn dialog-h5-show">
        <el-button type="primary" @click="openDownload('h5')">下 载</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="'《'+dialog.title+'》'"
               :visible.sync="dialogFreeDownload"
               :modal-append-to-body="false"
               class="free-dialog"
               @close="closeFreeDownload"
               :destroy-on-close="true"
               center>
      <div class="free-txt">
        <div class="free-title">
          <i class="el-icon-wallet"></i>
          <span>下载权益</span>
        </div>
        <div class="free-cont">
          <p class="top">今日免费下载次数剩余 <span>{{ downloadNum }}</span> 次</p>
          <p>(会员每日可下载 <span>{{ todayTotalNums.todayCaseDownloadTotalNum }}</span> 次)</p>
        </div>
        <div class="free-btn">
          <el-button @click="dialogFreeDownload = false">取 消</el-button>
          <el-button type="primary" @click="openFreeDownload">确认下载</el-button>
        </div>
      </div>
    </el-dialog>

    <!--    <DownloadH5 ref="Download" @downloadOff="downloadOff"/>-->
    <DownloadWay ref="DownloadWay"
                 @downloadDeduction="downloadDeduction"
                 @restartTimer="restartTimer"
                 @userSetInfo="getuserSetInfo"
                 @downloadOnWay="downloadOnWay"
                 @downloadOff="downloadOff"
                 @downloadOffWay="downloadOffWay"/>

  </div>
</template>
<script>
import CaseApi from '@/api/case'
import CommonApi from "@/api/common"
import generateQRCode from '@/components/generateQRCode/generateQRCode.vue'
// import DownloadH5 from '@/components/downloadH5/downloadH5.vue'
import DownloadWay from "@/components/downloadWay/index.vue"
import UserPromotApi from "@/api/user"
import {EventBus} from "@/utils/eventBus"
import FormTitle from "@/components/FormTitle/index.vue"
import RequestlogApi from "@/api/requestlog"

export default {
  name: 'CaseLibrary',
  components: {FormTitle, DownloadWay, generateQRCode },
  props: ['transferMent','infoName'],
  data() {
    return {
      queryInfo: {
        creationDocId: null,
        pageNum: 1,
        pageSize: 14,
        title: ''
      },
      input3: '',
      select: '',
      showTag: false,
      searchData: [{
        id: 1,
        active: 0,
        name: '疫情',
      },{
        id: 1,
        active: 0,
        name: '汉语言文学'
      },{
        id: 7,
        active: 0,
        name: '心得体会'
      },{
        id: 8,
        active: 0,
        name: '问卷设计'
      },{
        id: 10,
        active: 0,
        name: '课程论文'
      },{
        id: 7,
        active: 0,
        name: '心得体会'
      },{
        id: 8,
        active: 0,
        name: '问卷设计'
      }],
      productList: [],
      total: 0,//列表总数据条数
      count: 10,
      loading: false,
      centerDialogVisible: false, //预览
      dialog: {
        title: '',
        content: ''
      },
      orderDetail: '',
      searchDrawer: false,
      showH5: false,
      storageItem: '',
      userInfo: {
        isLogin: false,
        username: '',
        nickname: '',
        token: ''
      },
      userSetInfo: '',
      caseOrderId: '',
      todayTotalNums: '',
      dialogFreeDownload: false
    }
  },
  watch:{
    transferMent(newVal) {
      this.select = newVal[0].name;
      this.queryInfo.creationDocId = newVal[0].id
      this.search(newVal[0].id)
    }
  },
  created() {
    if(localStorage.getItem('userInfo')){
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    }
    if(localStorage.getItem('userSetInfo')){
      this.userSetInfo = JSON.parse(localStorage.getItem('userSetInfo'))
    }
    this.report()
  },
  mounted() {
    let _this = this
    // 禁用复制
    // this.$nextTick(() => {
    //   document.onselectstart = new Function("event.returnValue=false");
    // })
    if(this.transferMent.length > 0){
      this.select = this.transferMent[0].name;
      this.queryInfo.creationDocId = this.transferMent[0].id
      this.search(this.transferMent[0].id)
    }
    EventBus.$on("userLogin", (msg) => {
      // 登录成功发送来的消息
      if(msg === 'userLoginTrue'){
        _this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        _this.userSetInfo = JSON.parse(localStorage.getItem('userSetInfo'))
        // 登录后重新打开下载弹窗
        if(_this.caseOrderId){
          _this.reopenDetailVo()
        }
      }
    })
    EventBus.$on('userSetInfo', (msg) => {
      if(msg === 'userInfoTrue'){
        _this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        _this.userSetInfo = JSON.parse(localStorage.getItem('userSetInfo'))
      }
    })
    EventBus.$on("userOutLogin", (msg) => {
      // 退出登录发送来的消息
      if(msg === 'userOutLoginTrue'){
        _this.userInfo = _this.$options.data().userInfo
        _this.userSetInfo = ''
      }
    })
  },
  beforeDestroy() {
    //清除定时器查询
    clearInterval(this.timer);
  },
  methods: {
    // 上报日志
    report(){
      let data = {
        type: 3,
        channelCode: '',
        description: ''
      }
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = this.$md5(setOf)
      RequestlogApi.report(data,key,time,infoMd5).then(res => {
        // console.log(res.data)
      }).catch(() => {
      })
    },
    // 登录后重新打开下载弹窗
    reopenDetailVo(){
      let _this = this
      // 清除定时器 关闭弹窗
      _this.$refs.DownloadWay.handleClose()
      // 重新打开弹窗
      // console.log(_this.pptOrderId)
      setTimeout(()=>{
        let item = {
          title: _this.dialog.title,
          id: _this.caseOrderId
        }
        _this.downloadBtn(item)
      },200)
    },
    getuserSetInfo(msg){
      if(msg){
        // this.getUserDetailVo()
        this.userSetInfo = JSON.parse(localStorage.getItem('userSetInfo'))
      }
    },
    searchBtn(item,index){
      if(index || index === 0){
        this.searchData.forEach(item => item.active = 0)
        this.searchData[index].active = 1
      }
      this.queryInfo.creationDocId = item.id
      this.queryInfo.title = item.name
      this.transferMent.forEach(val => {
        if(val.id === item.id){
          this.select = val.name
        }
      })
      this.queryInfo.pageNum = 1
      // this.search()
      this.searchDrawer = false
    },
    closeActive(){
      this.searchData.forEach(item => item.active = 0)
    },
    // input change 事件
    changeSearch(){
      this.queryInfo.pageNum = 1
      this.search()
    },
    // 鼠标移入移出事件
    mouseEnter(index){
      this.productList[index].showTag = true
    },
    mouseLeave(index){
      this.productList[index].showTag = false
    },
    // 查询获取
    search(){
      let _this=this;
      CaseApi.postPage(_this.queryInfo).then(res => {
        if(res.data.total > 0){
          res.data.data.forEach(item => {
            item.showTag = false
          })
          _this.productList = res.data.data
          _this.total = res.data.total
        }else {
          this.productList = []
          _this.total = 0
        }
      }).catch(() => {
      })
    },
    // 获取预览
    preview(id){
      let _this = this;
      CaseApi.preview(id).then(res => {
        _this.dialog.content = res.data
        _this.centerDialogVisible = true
      }).catch(() => {
      })
    },
    // 获取下载
    postCreateOrder(){
      let _this = this;
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = this.$md5(setOf)
      let data = {
        caseId: _this.caseOrderId,
      }
      if(localStorage.getItem('shareCode')){
        data.shareCode = localStorage.getItem('shareCode')
      }
      CaseApi.postCreateOrder(data,key,time,infoMd5).then(res => {
        _this.orderDetail = res.data
        // 移动端 H5 适配
        if(_this.showH5){
          let params = {
            show: true,
            title: '下载 《' + _this.dialog.title + '》',
            info: _this.orderDetail
          }
          // _this.$refs.Download.showDrawer(params)
          _this.$refs.DownloadWay.showDrawer(params)
          _this.getTimeFun(_this.orderDetail.id)
        }else {
          let params = {
            show: true,
            title: '下载 《' + _this.dialog.title + '》',
            info: _this.orderDetail
          }
          _this.$refs.DownloadWay.showDrawer(params)
          _this.getTimeFun(_this.orderDetail.id)
        }
      }).catch(() => {
      })
    },
    // 监听选项事件
    changeType(value){
      this.queryInfo.creationDocId = this.transferMent[value - 1].id
      this.queryInfo.pageNum = 1
      this.search()
    },
    // 查看详情按钮
    previewBtn(item){
      this.storageItem = ''
      this.storageItem = item
      this.dialog.title = item.title
      this.preview(item.id)
    },
    // 下载按钮
    downloadBtn(item){
      let _this = this
      this.dialog.title = item.title
      this.caseOrderId = item.id
      if(_this.userSetInfo && _this.userSetInfo.vipInfoVos.length){
        let result = _this.userSetInfo.vipInfoVos.some(item => 'vipCode' in item && item.vipCode.includes('CASE_VIP'))
        if(result){
          _this.getVipDownloadInfo()
        }else {
          _this.postCreateOrder()
        }
      }else {
        _this.postCreateOrder()
      }
    },
    // 下载按钮 h5
    downloadBtn2(item){
      let _this = this
      _this.showH5 = true
      _this.dialog.title = item.title
      _this.caseOrderId = item.id
      if(_this.userSetInfo && _this.userSetInfo.vipInfoVos.length){
        let result = _this.userSetInfo.vipInfoVos.some(item => 'vipCode' in item && item.vipCode.includes('CASE_VIP'))
        if(result){
          _this.getVipDownloadInfo()
        }else {
          _this.postCreateOrder()
        }
      }else {
        _this.postCreateOrder()
      }
    },
    // 关闭弹窗 清除定时器 还原状态
    downloadOff(info){
      this.showH5 = info.showState
      if(!info.payState){
        clearInterval(this.timer)
      }
    },
    // 清除定时器回调
    downloadOffWay(info){
      if(info.close){
        clearInterval(this.timer)
      }
    },
    // 支付成功回调
    downloadOnWay(info){
      if(info.payState){
        this.getQueryPayState(info.id)
      }
    },
    // 勾选抵扣的回调
    downloadDeduction(info){
      let _this = this
      if(info.state){
        _this.postQueryOrderDetail()
      }
    },
    // 重启定时器
    restartTimer(info){
      let _this = this
      if(info.close){
        _this.getTimeFun(_this.orderDetail.id)
      }
    },
    // 查询订单支付状态
    getQueryPayState(orderId){
      let _this = this
      CommonApi.queryPayState(orderId).then(res => {
        if(res.data){
          //清除定时器查询
          clearInterval(_this.timer)
          // 关闭弹窗
          _this.$refs.DownloadWay.handleClose()
          _this.getuserSetInfo(true)
          //主动调用下载接口
          let time = new Date().getTime()
          let key = localStorage.getItem('key')
          let ticket = localStorage.getItem('ticket')
          let setOf = ticket + time + ticket + ticket + time + ticket
          let infoMd5 = _this.$md5(setOf)
          CommonApi.getDownLink(orderId,key,time,infoMd5).then(res => {
            window.location.href = res.data;
          }).catch(() => {
          })
        }
      }).catch(() => {
      })
    },
    //定时器查询订单状态
    getTimeFun(id){
      this.timer = setInterval(() => {
        this.getQueryPayState(id)
      }, 3000);
    },
    // 关闭预览
    closePreview(){
      // console.log('关闭了预览')
      // this.dialog.title = ''
      this.dialog.content = ''
    },
    // 修改每页条数
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
    },
    // 关闭预览 并 打开下载弹窗
    openDownload(type){
      // console.log(type)
      let _this = this;
      _this.centerDialogVisible = false
      let dataList = {
        title: _this.storageItem.title,
        id: _this.storageItem.id
      }
      if(type === 'pc'){
        _this.downloadBtn(dataList)
      }else if(type === 'h5'){
        _this.downloadBtn2(dataList)
      }
    },
    // 查询订单信息
    postQueryOrderDetail() {
      let _this = this
      CommonApi.queryOrderDetail(_this.orderDetail.id).then(res => {
        _this.orderDetail = res.data
        // 如果支付金额为 0 显示支付按钮
        // _this.payBtn = Number(_this.orderDetail.price) === 0;
        let params = {
          show: true,
          title: '下载 《' + _this.dialog.title + '》',
          info: _this.orderDetail
        }
        _this.$refs.DownloadWay.showDrawer(params)
        _this.getTimeFun(_this.orderDetail.id)
      }).catch(() => {
      })
    },
    // 获取Vip下载次数信息
    getVipDownloadInfo(){
      let _this = this
      UserPromotApi.getVipDownloadInfo(_this.userInfo.token).then(res => {
        // console.log(res.data)
        _this.todayTotalNums = res.data
        if(this.downloadNum > 0){
          setTimeout(()=>{
            _this.dialogFreeDownload = true
          },100)
        }else {
          _this.$message.warning('今日免费下载次数已用完')
          setTimeout(()=>{
            _this.postCreateOrder()
          },100)
        }
        // todayCaseDownloadTotalNum	今日案例可下载免费总次数
        // todayPptDownloadTotalNum	今日PPT可下载免费总次数
        // todayUseCaseDownloadNum	今日已使用案例免费下载次数
        // todayUsePptDownloadNum	今日已使用PPT免费下载次数
      }).catch(() => {
      })
    },
    // vip下载 打开
    openFreeDownload(){
      this.getVipDownLink(this.caseOrderId)
    },
    // vip下载 关闭
    closeFreeDownload(){
      this.dialogFreeDownload = false
    },
    // vip下载接口
    getVipDownLink(orderId){
      let _this = this
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = _this.$md5(setOf)
      CaseApi.getVipDownLink(orderId,_this.userInfo.token,key,time,infoMd5).then(res => {
        _this.$message.success('下载成功')
        _this.closeFreeDownload()
        window.location.href = res.data
      }).catch(() => {
      })
    }
  },
  computed: {
    noMore () {
      return this.count >= 20
    },
    disabled () {
      return this.loading || this.noMore
    },
    downloadNum(){
      let t = Number(this.todayTotalNums.todayCaseDownloadTotalNum)
      let u = Number(this.todayTotalNums.todayUseCaseDownloadNum)
      return t - u
    }
  }
}
</script>
<style scoped lang="less">
.case-main-box{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 20px 20px 0 20px;

  .search-box{
    width: calc(100% - 20px);
    height: 184px;
    padding-right: 20px;
    background: url("../../assets/images/search-banner.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .input-with-select{
      width: 58%;
      max-width: 880px;
      height: 60px;
      margin-right: 15%;
      /deep/ .el-input-group__prepend{
        border: 0;
        border-radius: 15px 0 0 15px;
        background-color: #ffffff;
        .el-input__inner{
          width: 160px;
          height: 40px;
          text-align: center;
          border-right: 1px solid #e4e4e4;
        }
      }
      /deep/ .el-input__inner{
        height: 60px;
        border: 0;
      }
      /deep/ .el-input-group__append{
        padding: 0;
        border: 0;
        border-radius: 0 15px 15px 0;
        background-color: #ffffff;
        .el-button{
          color: #ffffff;
          background: linear-gradient(180deg,#afa6ff,#6f84f7);
          width: 100px;
          height: 50px;
          margin-right: 5px;
          border-radius: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          span{
            font-size: 16px;
          }
          i{
            font-size: 22px;
          }
        }
        .el-button:hover{
          background: linear-gradient(0deg,#409EFF,#409EFF);
        }
      }
    }
  }
  .search-pc-show{
    //display: block;
  }
  .search-h5-show{
    display: none;
  }
  .hot-search-box{
    width: 100%;
    min-height: 60px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .tit{
      width: 80px;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: bold;
    }
    ul{
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      li{
        padding: 0 20px;
        margin-bottom: 10px;
        height: 30px;
        line-height: 30px;
        margin-right: 20px;
        border-radius: 30px;
        color: #101010;
        cursor: pointer;
        background-color: #ffffff;
        box-shadow: 0 0 5px #e4e4e4;
      }
      li:hover,li.active{
        color: #ffffff;
        background-color: #409EFF;
      }
    }
  }
  .case-content{
    max-width: 1470px;
    margin: 0 auto;
    padding: 20px 0 50px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    .list-box{
      position: relative;
      width: 180px;
      height: 265px;
      margin: 0 30px 30px 0;
      box-sizing: border-box;
      overflow: hidden;
      cursor: pointer;
      border-bottom: 1px solid #c9c9c9;
      .list-icon{
        position: absolute;
        z-index: 10;
        top: 14px;
        left: 20px;
        width: 20px;
        height: 20px;
        img{
          width: 100%;
          height: 100%;
          border: 0;
        }
      }
      .cont{
        //width: 90%;
        min-height: 254px;
        margin: -15px auto 0 auto;
        padding: 15px 10px;
        border-radius: 10px;
        border: 1px solid #d4d4d4;
        background-color: #ffffff;
        transform: scale(0.878);
        .cont-txt{
          width: 90%;
          margin: 12px;
          line-height: 18px;
          text-indent: 28px;
          font-size: 8px;
          color: #686868;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          line-clamp: 11;
          -webkit-line-clamp: 11;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .list-item{
        position: relative;
        width: 100%;
        padding-bottom: 20px;
        margin-top: -40px;
        background-color: rgba(255, 255, 255, 0.8);
        border: 1px solid #c9c9c9;
        transition: transform 0.5s ease-in-out;
        transform: translateY(0px);
        .list-title{
          width: 100%;
          min-height: 64px;
          line-height: 22px;
          padding: 10px;
          border-radius: 10px;
          text-align: left;
          color: #101010;
          font-size: 12px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          line-clamp: 2;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .tag{
          width: 110%;
          overflow: hidden;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          span{
            padding: 0 10px;
            margin: 5px 5px 0 0;
            height: 20px;
            line-height: 20px;
            border-radius: 10px;
            font-size: 12px;
            color: #ffffff;
            background-color: #86b8f4;
          }
          //span:nth-child(4n+1){background-color: #409EFF;}
          //span:nth-child(4n+2){background-color: #67c23a;}
          //span:nth-child(4n+3){background-color: #e6a23c;}
          //span:nth-child(4n){background-color: #909399;}
        }
        .show-btn2{
          width: 100%;
          border-radius: 15px;
          margin-top: 5px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          .e-btn{
            padding: 3px 8px;
            border-radius: 5px;
            color: #409EFF;
            background-color: #ffffff;
            border: 1px solid #d7d7d7;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            i{
              font-size: 14px !important;
            }
            span{
              margin-left: 5px;
              font-size: 12px;
            }
          }
          .e-btn:hover{
            color: #ffffff;
            background-color: #7bb6fd;
            border: 1px solid #7bb6fd;
          }
          .e-btn:last-child{
            color: #ffffff;
            background-color: #409EFF;
            border: 1px solid #409EFF;
          }
          .e-btn:last-child:hover{
            background-color: #7bb6fd;
            border: 1px solid #7bb6fd;
          }
          .btn-pc-show{
            //display: block;
          }
          .btn-h5-show{
            display: none;
          }
        }
      }
      .list-item::before{
        content: '';
        display: block;
        position: absolute;
        top: -4px;
        left: -2px;
        width: 13px;
        height: 1px;
        background-color: #c9c9c9;
        transform: rotate(-30deg);
      }
      .list-item::after{
        content: '';
        display: block;
        position: absolute;
        top: -4px;
        right: -2px;
        width: 13px;
        height: 1px;
        background-color: #c9c9c9;
        transform: rotate(30deg);
      }
    }
    .list-box:hover{
      .list-item{
        transform: translateY(-44px);
      }
    }
  }
  .case-paging{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }
  .details-box{
    width: 100%;
    padding: 20px;
    .details-list{
      margin: 10px 0;
      //h1 {
      //  font-size: 30px;
      //  font-weight: 700;
      //  color: #34383e;
      //  margin-bottom: 30px;
      //  margin-top: 10px
      //}
      /deep/ .case-container{
        h1 {
          text-align: center;
          font-size: 30px;
          font-weight: 700;
          color: #34383e;
          margin-bottom: 30px;
          margin-top: 10px
        }
        .content,.contentEn {
          line-height: 22px;
          font-size: 14px;
          text-indent: 2em;
          font-weight: 400;
          color: #182b50
        }
        h2 {
          font-size: 24px;
          margin-bottom: 24px;
          margin-top: 10px;
          font-weight: 700;
          color: #34383e
        }
        .outline1 {
          //line-height: 30px;
          text-indent: 1em;
          //font-size: 18px;
          //font-weight: 700;
          //color: #34383e
          line-height: 22px;
          font-size: 14px;
          font-weight: 400;
          color: #182b50
        }
        .outline2 {
          text-indent: 2em;
          //font-size: 16px;
          //line-height: 38px;
          //font-weight: 700;
          //color: #34383e
          line-height: 22px;
          font-size: 14px;
          font-weight: 400;
          color: #182b50
        }
        .outline3 {
          text-indent: 3em;
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          color: #182b50
        }
        .ref {
          line-height: 22px
        }
      }
    }
  }

  .dialog-box{
    /deep/ .el-dialog{
      width: 720px;
      min-width: 720px !important;
      height: 90vh;
      margin-top: 5vh !important;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      .el-dialog__close{
        font-size: 24px;
      }
      .el-dialog__body{
        height: calc(100% - 54px);
        padding: 0;
      }
    }
    .details-box{
      width: 100%;
      height: calc(100% - 50px);
      overflow: auto;
    }
    .dialog-btn{
      height: 50px;
      line-height: 50px;
      text-align: center;
    }
    .dialog-pc-show{display: block}
    .dialog-h5-show{display: none}
  }
  // 禁用复制
  .no-select {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none;    /* Firefox */
    -ms-user-select: none;     /* IE 10 and IE 11 */
    user-select: none;         /* Standard syntax */
  }
  .free-dialog{
    /deep/ .el-dialog{
      width: 540px;
      border-radius: 15px;
      .el-dialog__close{
        font-size: 24px;
      }
    }
    .free-txt{
      width: 440px;
      min-height: 200px;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid #ffeacd;
      border-radius: 15px;
      .free-title{
        i{
          font-size: 18px;
          color: #e6a23c;
        }
        span{
          margin-left: 6px;
          font-size: 16px;
          color: #e6a23c;
        }
      }
      .free-cont{
        padding-top: 34px;
        p{
          text-align: center;
          font-size: 14px;
          letter-spacing: 2px;
          color: #cccccc;
          span{
            color: #3c3c3c;
          }
        }
        p.top{
          padding-bottom: 6px;
          color: #3c3c3c;
          font-size: 16px;
          span{
            font-size: 24px;
            color: #fc5151;
          }
        }
      }
      .free-btn{
        padding-top: 34px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .case-main-box{
    height: calc(100% - 66px);

    .search-box2{
      width: 100%;
      margin: 10px 0;
      background: transparent;
      .search-h5-select{
        width: 80px;
        /deep/ .el-input{
          font-size: 12px !important;
          .el-input__inner{
            padding: 0 10px;
          }
          .el-input__suffix{
            right: 0;
          }
        }
      }
      .search-h5-btn{
        width: 44px;
        height: 30px;
        border-radius: 0 5px 5px 0;
        color: #ffffff;
        background-color: #6e83f7;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
    .search-pc-show{
      display: none;
    }
    .search-h5-show{
      display: block;
    }
    .hot-search-box2{
      width: 100%;
      height: 32px;
      line-height: 32px;
      border-radius: 5px;
      background-color: #ffffff;
      .tit{
        padding: 0 12px;
        font-size: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
    .case-content{
      width: 100%;
      padding: 20px 0;
      justify-content: space-between;
      .list-box{
        width: 48%;
        min-width: 124px;
        height: 160px;
        margin: 0 0 20px 0;
        .list-icon{
          top: 8px;
          left: 15px;
        }
        .cont{
          min-height: 160px;
          .cont-txt{
            margin: 7px;
            text-indent: 18px;
            line-clamp: 7;
            -webkit-line-clamp: 7;
          }
        }
        .list-item{
          margin-top: -44px;
          .list-title{
            width: calc(100% - 10px);
            min-height: 38px;
            padding: 0;
            margin: 5px;
            font-size: 11px;
            line-height: 18px;
          }
          .show-btn2{
            .e-btn{
              padding: 0 8px;
              min-height: 24px;
              line-height: 24px;
              i{
                font-size: 12px !important;
              }
              span{
                font-size: 10px;
              }
            }
            .btn-pc-show{
              display: none;
            }
            .btn-h5-show{
              display: flex;
            }
          }
        }
        .list-item::before{
          width: 10px;
        }
        .list-item::after{
          width: 10px;
        }
      }
      .list-box:hover{
        .list-item{
          transform: translateY(-36px);
        }
      }
    }
    .dialog-box{
      /deep/ .el-dialog{
        width: 90%;
        min-width: 300px !important;
        .el-dialog__header{
          padding: 20px 30px 10px;
          height: 78px;
          .el-dialog__title{
            font-size: 14px;
          }
          .el-dialog__headerbtn{
            top: 10px;
            right: 10px;
          }
        }
        .el-dialog__body{
          width: 100%;
          height: calc(100% - 78px);
          padding: 0;
          .dialog-img{
            width: 100%;
          }
        }
        .dialog-pc-show{display: none}
        .dialog-h5-show{display: block}
      }
    }
    .free-dialog{
      /deep/ .el-dialog{
        width: 90%;
        .el-dialog__header{
          padding: 20px 30px 10px;
          .el-dialog__title{
            font-size: 14px;
          }
        }
        .el-dialog__body{
          width: 100%;
          padding: 0 0 10px 0;
        }
      }
      .free-txt{
        width: 94%;
        padding: 15px;
        .free-title{
          i{
            font-size: 14px;
          }
          span{
            font-size: 14px;
          }
        }
        .free-cont{
          padding-top: 24px;
          p{
            font-size: 12px;
            letter-spacing: 1.5px;
          }
          p.top{
            font-size: 14px;
            span{
              font-size: 20px;
            }
          }
        }
        .free-btn{
          padding-top: 24px;
        }
      }
    }
  }
}

</style>
<style lang="less">
.page-pagination{
  padding: 20px 0 50px 0;
  text-align: center;
}
@media screen and (max-width: 900px) {
  .page-pagination{
    padding: 10px 0 30px 0;
    text-align: center;
  }
  .search-h5-box{
    width: 60% !important;
    .el-drawer__header{
      padding: 10px;
      margin-bottom: 0;
    }
    .search-h5-ul{
      width: 100%;
      height: 100%;
      overflow: auto;
      box-sizing: border-box;
      padding: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      li{
        width: 48%;
        padding: 0 12px;
        margin-bottom: 15px;
        text-align: center;
        height: 24px;
        line-height: 24px;
        border-radius: 5px;
        font-size: 10px;
        color: #101010;
        cursor: pointer;
        background-color: #ffffff;
        box-shadow: 0 0 5px #e4e4e4;
      }
      li.active{
        color: #ffffff;
        background-color: #409EFF;
      }
    }
  }
}
</style>
